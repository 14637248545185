var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('login-card',{attrs:{"header-color":"indigo"}},[_c('h4',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_vm._v("Resetar Password")]),_c('p',{staticClass:"description",attrs:{"slot":"description"},slot:"description"},[_vm._v("...")]),_c('div',{attrs:{"slot":"inputs"},slot:"inputs"},[_c('ValidationProvider',{attrs:{"name":"token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("vpn_key")]),_c('label',[_vm._v("Token")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.user.token),callback:function ($$v) {_vm.$set(_vm.user, "token", $$v)},expression:"user.token"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("Email")]),_c('md-input',{attrs:{"disabled":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Senha","rules":{
                regex: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
                required: true
              },"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Confirmação de senha","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid"},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',[_vm._v("Confirm Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"button-container",attrs:{"slot":"footer"},slot:"footer"},[_c('md-button',{staticClass:"md-indigo md-round mt-4",attrs:{"type":"submit"}},[_vm._v(" Atualizar ")])],1)])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }