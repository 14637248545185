<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <login-card header-color="indigo">
            <h4 slot="title" class="title">Resetar Password</h4>
            <p slot="description" class="description">...</p>
            <div slot="inputs">
              <ValidationProvider
                name="token"
                rules="required"
                v-slot="{ errors }"
              >
                <md-field
                  class="md-form-group md-invalid"
                  style="margin-bottom: 2rem"
                >
                  <md-icon>vpn_key</md-icon>
                  <label>Token</label>

                  <md-input v-model="user.token" disabled />
                  <validation-error :errors="errors" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="e-mail"
                rules="required|email"
                v-slot="{ errors }"
              >
                <md-field
                  class="md-form-group md-invalid"
                  style="margin-bottom: 2rem"
                >
                  <md-icon>email</md-icon>
                  <label>Email</label>

                  <md-input v-model="user.email" disabled />
                  <validation-error :errors="errors" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="Senha"
                :rules="{
                  regex: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
                  required: true
                }"
                v-slot="{ errors }"
                vid="password"
              >
                <md-field
                  class="md-form-group md-invalid"
                  style="margin-bottom: 2rem"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>Password</label>
                  <md-input v-model="user.password" type="password" />
                  <validation-error :errors="errors" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="Confirmação de senha"
                rules="confirmed:password"
                v-slot="{ errors }"
              >
                <md-field class="md-form-group md-invalid">
                  <md-icon>lock_outline</md-icon>
                  <label>Confirm Password</label>
                  <md-input
                    v-model="user.password_confirmation"
                    type="password"
                  />
                  <validation-error :errors="errors" />
                </md-field>
              </ValidationProvider>
            </div>

            <!--
              <md-checkbox v-model="boolean">
                Eu concordo com os <a>Termos e Condições</a>.</md-checkbox
              >
              -->
            <div class="button-container" slot="footer">
              <md-button class="md-indigo md-round mt-4" type="submit">
                Atualizar
              </md-button>
            </div>
          </login-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Vue from "vue";

export default {
  components: {
    LoginCard,
    ValidationError
  },
  mixins: [formMixin],
  data() {
    return {
      user: {
        token: null,
        email: null,
        password: null,
        password_confirmation: null
      },
      load: false
    };
  },
  created: function() {
    this.user.email = this.$route.params.email;
    this.user.token = this.$route.params.token;
  },
  methods: {
    async updatePassword() {
      try {
        await Vue.$http.post(`v1/reset-password`, this.user);
        await this.$store.dispatch(
          "alerts/success",
          "Profile updated successfully."
        );

        this.load = false;
        this.$router.push("/login");
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        this.load = false;
      }
    },
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }

        this.updatePassword();
        // Resetting Values
        //this.firstName = this.lastName = this.email = "";

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          //this.$refs.form.reset();
        });
      });
    }
  }
};
</script>
